import { getAccount } from "./Utils";

const DATA = getAccount() == undefined ? "NONE" : getAccount().toUpperCase()

export const flow_DEEP = {
	"@User_initial": {
		"type": "#user",
		"start": true,
		"name": "Você Perguntando",
		"cache": "#overwrite()",
		"ask": "${Pergunta original}",
		"goto": "@Agent_paraphraser"
	},
	"@Agent_paraphraser": {
		"type": "#agent",
		"function": "dialoger",
		"name": "Bot Parafraseador",
		"scheme": "ragnrock-ambev-paraf-0.0.0",
		"cases": {
			"!!${Restrição}": "@User_ban",
			"!!${Dúvida}": "@User_complement_say",
			"${Pergunta melhorada}": "@Agent_rationalizer",
			"$else": "@User_initial"
		},
		"cache": "#overwrite()"
	},
	"@User_ban": {
		"type": "#bot",
		"say": "${Restrição}",
		"name": "Bot Censor",
		"goto": "@User_initial",
		"cache": "#reset()"
	},	
	"@User_complement_say": {
		"type": "#bot",
		"say": "Baseado em sua pergunta original: \"<b>${Pergunta original}</b>\", tenho alguns pontos para esclarecer. <br/>${Dúvida}",
		"name": "Bot Analista",
		"goto": "@User_complement_ask"
	},
	"@User_complement_ask": {
		"type": "#user",
		"ask": "${Esclarecimento}",
		"name": "Você Complementando",
		"goto": "@Agent_paraphraser",
		"attrib": "\"Dúvida: \" + ${Dúvida} + \" Esclarecimento:\" + ${Esclarecimento}",
		"cache": "#append(${Complemento})"
	},
	"@Agent_rationalizer": {
		"type": "#bot",
		"say": "Eis a pergunta reconstruída: ${Pergunta melhorada}",
		"name": "Bot Analista",
		"goto": "@Agent_rationalizer"
	},
	"@Agent_rationalizer": {
		"type": "#agent",
		"function": "dialoger",
		"name": "Bot Racionalizador",
		"scheme": "ragnrock-ambev-rat-0.0.0",
		"goto": "@User_just_to_know",
		"cache": "#overwrite()"
	},
	"@User_just_to_know": {
		"type": "#bot",
		"say": "Baseado em sua pergunta original: \"<b>${Pergunta original}</b>\", segue uma versão mais detalhada do que eu entendi!<br/><b>${Pergunta melhorada}</b> <br/> Para chegar lá, vou seguir o seguinte racional de cálculo: ${Racional de cálculo}",
		"name": "Bot Confirmador",
		"goto": "@Agent_formulator_1"
	},
	"@Agent_formulator_1": {
		"type": "#agent",
		"function": "dialoger",
		"name": "Bot Formulador",
		"scheme": "ragnrock-ambev-form-0.0.0",
		"goto": "@Agent_formulator_2",
		"cache": "#overwrite()"
	},
	"@Agent_formulator_2": {
		"type": "#agent",
		"function": "dialoger",
		"name": "Bot Formulador",
		"scheme": "ragnrock-ambev-prog-0.0.0",
		"goto": "@Tool_validator",
		"cache": "#overwrite()"
	},
	"@Tool_validator": {
		"type": "#tool",
		"name": "Checador de Fórmula",
		"url": "calculator/check",
		"body": {
			"calculation": {
				"Resultado": "${Código principal}",
				"Preliminares": "${Códigos intermediários}"
			},
			"name": DATA == "AMBEV" ? "AMBEV_POC" : "SUPERLÓGICA_POC",
			"target": "Valor"
		},
		"cases": {
			"!!${Erro}": "@Erro_formula",
			"$else": "@Tool_calculator"
		},
		"cache": "#overwrite()"
	},
	"@Erro_formula": {
		"type": "#bot",
		"say": "Infelizmente não foi possível atender a sua solicitação nessa versão. Estamos trabalhando para aumentar os poderes dessa solução. <br />Por favor, tente perguntar o que você me pediu de uma forma diferente, sempre um pedido de cada vez.",
		"name": "Bot Humilde",
		"goto": "@User_initial",
		"cache": "#reset()"
	},
	"@Tool_calculator": {
		"type": "#tool",
		"name": "Avaliador de Fórmula",
		"url": "calculator/eval",
		"body": {
			"calculation": {
				"Resultado": "${Código principal}",
				"Preliminares": "${Códigos intermediários}"
			},
			"name": DATA == "AMBEV" ? "AMBEV_POC" : "SUPERLÓGICA_POC",
			"target": "Valor"
		},
		"goto": "@Agent_analyser",
		"cache": "#overwrite()"
	},
	"@Agent_analyser": {
		"type": "#agent",
		"function": "dialoger",
		"name": "Bot Analítico",
		"scheme": "ragnrock-ambev-anal-0.0.0",
		"goto": "@Agent_reporter",
		"cache": "#overwrite()"
	},
	"@Agent_reporter": {
		"type": "#agent",
		"function": "dialoger",
		"name": "Bot Reportador",
		"scheme": "ragnrock-ambev-rep-0.0.0",
		"send": ["Pergunta original", "Pergunta melhorada", "Racional de cálculo", "Fórmulas intermediárias", "Explicação das fórmulas intermediárias", "Fórmula principal", "Resultado"],
		"goto": "@Bot_answer",
		"cache": "#overwrite()"
	},
	"@Bot_answer": {
		"publish": true,
		"type": "#bot",
		"say": "Aqui estão seus resultados. Deseja algo mais?",
		"name": "Bot Publicador",
		"goto": "@User_initial_add"
	},
	"@User_initial_add": {
		"type": "#user",
		"name": "Você Perguntando de Novo",
		"goto": "@Agent_paraphraser",
		"remains": ["Pergunta original", "Pergunta melhorada", "Resultado"],
		"cache": "#overwrite()"
	}
}

export const flow = {
	"@User_initial": {
		"type": "#user",
		"start": true,
		"name": "Você Perguntando",
		"goto": "@Agent_paraphraser",
		"cache": "#overwrite()",
		"ask": "${Pergunta original}"
	},
	"@Agent_paraphraser": {
		"type": "#agent",
		"function": "dialoger",
		"name": "Bot Parafraseador",
		"scheme": DATA == "AMBEV" ? "ragnrock-paraf-0.0.0" : "ragnrock-sl-paraf-0.0.0",
		"cases": {
			"!!${Restrição}": "@User_ban",
			"!!${Dúvida}": "@User_complement_say",
			"$else": "@User_just_to_know"
		},
		"cache": "#overwrite()"
	},
	"@User_just_to_know": {
		"type": "#bot",
		"say": "Baseado em sua pergunta original: \"<b>${Pergunta original}</b>\", segue uma versão mais detalhada do que eu entendi!<br/><b>${Pergunta melhorada}</b> <br/> Para chegar lá, vou seguir o seguinte racional de cálculo: ${Racional de cálculo}",
		"name": "Bot Confirmador",
		"goto": "@Agent_formulator_1"
	},
	"@User_ban": {
		"type": "#bot",
		"say": "${Restrição}",
		"name": "Bot Censor",
		"goto": "@User_initial",
		"cache": "#reset()"
	},
	"@User_complement_say": {
		"type": "#bot",
		"say": "Baseado em sua pergunta original: \"<b>${Pergunta original}</b>\", tenho alguns pontos para esclarecer. <br/>${Dúvida}",
		"name": "Bot Analista",
		"goto": "@User_complement_ask"
	},
	"@User_complement_ask": {
		"type": "#user",
		"ask": "${Complemento}",
		"name": "Você Complementando",
		"goto": "@Agent_paraphraser",
		"cache": "#appendAll()"
	},
	"@Agent_formulator_1": {
		"type": "#agent",
		"function": "dialoger",
		"name": "Bot Formulador",
		"scheme": DATA == "AMBEV" ? "ragnrock-preform-0.0.0" : "ragnrock-sl-preform-0.0.0",
		"goto": "@Agent_formulator_2",
		"cache": "#overwrite()"
	},
	"@Agent_formulator_2": {
		"type": "#agent",
		"function": "dialoger",
		"name": "Bot Formulador",
		"scheme": DATA == "AMBEV" ? "ragnrock-posform-0.0.0" : "ragnrock-sl-posform-0.0.0",
		"goto": "@Tool_validator",
		"cache": "#overwrite()"
	},
	"@Tool_validator": {
		"type": "#tool",
		"name": "Checador de Fórmula",
		"url": "calculator/check",
		"body": {
			"calculation": {
				"Resultado": "${Código principal}",
				"Preliminares": "${Códigos intermediários}"
			},
			"name": DATA == "AMBEV" ? "AMBEV_POC" : "SUPERLÓGICA_POC",
			"target": "Valor"
		},
		"cases": {
			"!!${Erro}": "@Erro_formula",
			"$else": "@Tool_calculator"
		},
		"cache": "#overwrite()"
	},
	"@Erro_formula": {
		"type": "#bot",
		"say": "Infelizmente não foi possível atender a sua solicitação nessa versão. Estamos trabalhando para aumentar os poderes dessa solução. <br />Por favor, tente perguntar o que você me pediu de uma forma diferente, sempre um pedido de cada vez.",
		"name": "Bot Humilde",
		"goto": "@User_initial",
		"cache": "#reset()"
	},
	"@Tool_calculator": {
		"type": "#tool",
		"name": "Avaliador de Fórmula",
		"url": "calculator/eval",
		"body": {
			"calculation": {
				"Resultado": "${Código principal}",
				"Preliminares": "${Códigos intermediários}"
			},
			"name": DATA == "AMBEV" ? "AMBEV_POC" : "SUPERLÓGICA_POC",
			"target": "Valor"
		},
		"goto": "@Agent_reporter",
		"cache": "#overwrite()"
	},
	"@Agent_reporter": {
		"type": "#agent",
		"function": "dialoger",
		"name": "Bot Reportador",
		"send": ["Pergunta original", "Pergunta melhorada", "Racional de cálculo", "Fórmulas intermediárias", "Explicação das fórmulas intermediárias", "Fórmula principal", "Resultado"],
		"scheme": DATA == "AMBEV" ? "ragnrock-rep-0.0.0" : "ragnrock-sl-rep-0.0.0",
		"goto": "@Bot_answer",
		"cache": "#overwrite()"
	},
	"@Bot_answer": {
		"publish": true,
		"type": "#bot",
		"say": "Aqui estão seus resultados. Deseja algo mais?",
		"name": "Bot Publicador",
		"goto": "@User_initial_add"
	},
	"@User_initial_add": {
		"type": "#user",
		"name": "Você Perguntando de Novo",
		"goto": "@Agent_paraphraser",
		"ask": "${Pergunta original}",
		"remains": ["Pergunta original", "Pergunta melhorada", "Resultado"],
		"cache": "#overwrite()"
	}
}
