import React, { useEffect, useRef, useState } from 'react';
import {useReactToPrint} from 'react-to-print';
import PropTypes from 'prop-types';
import './ResultPanel.css';

const ResultPanel = ({ message }) => {

  const iframeRef = useRef(null);
  const contentRef = useRef(null);
  const reactToPrintFn = useReactToPrint({ contentRef });
  const [iframeHeight, setIframeHeight] = useState(800);
  const [rational, setRational] = useState(null);


  useEffect(() => {

    if (window.MathJax) {
      window.MathJax.typeset(); // Usar typeset diretamente para MathJax v3
    }
    if (iframeRef.current) {
      iframeRef.current.addEventListener('load', resizeIframe);
    }
    return () => {
      if (iframeRef.current) {
        iframeRef.current.removeEventListener('load', resizeIframe);
      }
    };
  }, [message]);

  const resizeIframe = () => {
    if (iframeRef.current) {
      const size = document.getElementById("main").contentWindow.document.body.scrollHeight * 1.2;
      document.getElementById("main").style.height = size+ "px";
    }
  };

  const renderWithMathJax = (value) => {
    if (!isProbablyLaTeX(value) && !isProbablyHTML(value)) {
      return <center>{value}</center>
    }
    const parts = value.split(/(\$[^$]+\$)/g);
    return parts.map((part, index) => {
      if (part.startsWith('$') && part.endsWith('$')) {
        const latex = part.slice(1, -1);
        return (
          <span style={{zoom:0.8}} key={index} dangerouslySetInnerHTML={{ __html: `\\(${latex}\\)` }} />
        );
      }
      return <span key={index} dangerouslySetInnerHTML={{ __html: part }} />;
    });
  };

  const isProbablyHTML = (inputString) => {
      const htmlTags = /<\s*(html|head|body|div|span|p|a|img|script|style|table|br|tr|td|th|ul|ol|li|h[1-6]|meta|link|form|input|button|select|option|textarea)[^>]*>/i;
      const htmlEntities = /&[a-zA-Z]+;/;
      const wellFormed = /<\s*[a-zA-Z]+[^>]*>.*<\/\s*[a-zA-Z]+[^>]*>/i;
      return htmlTags.test(inputString) || htmlEntities.test(inputString) || wellFormed.test(inputString);
  }

  const isProbablyLaTeX = (inputString) => {
      const latexCommands = /\\(begin|end|documentclass|usepackage|section|subsection|textbf|textit|frac|sqrt|ref|cite|label|item|itemize|enumerate|title|author|date)\b/;
      const latexMathSymbols = /(\$[^\$]*\$|\$\$[^\$]*\$\$|\\\[.*\\\]|\\\(.*\\\))/;
      const latexEnvironments = /\\begin\s*\{(equation|align|itemize|enumerate|figure|table|tabular)\}/;
      return latexCommands.test(inputString) || latexMathSymbols.test(inputString) || latexEnvironments.test(inputString);
  }

  const isValidDataStructure = (dataArray) => {
      return Array.isArray(dataArray) && dataArray.every(item => typeof item === 'object' && item !== null);
  }

  const transformToHTMLTable = (dataArray) => {
      const keys = Array.from(new Set(dataArray.flatMap(item => Object.keys(item))));
      return (
          <table>
              <thead>
                  <tr>
                      {keys.map(key => <th style={{border: "1px solid", padding: 5}} key={key}>{key}</th>)}
                  </tr>
              </thead>
              <tbody>
                  {dataArray.map((item, index) => (
                      <tr key={index}>
                          {keys.map(key => <td style={{border: "1px solid", padding: 5, fontWeight: "normal"}} key={key}>{item[key] !== undefined ? item[key] : null}</td>)}
                      </tr>
                  ))}
              </tbody>
          </table>
      );
  }

  const toString = (r) => {
    if (Array.isArray(r))
      return r.map(rp=>(<div>{toString(rp)}</div>));
    if (typeof(r) == "boolean")
      return r == true ? "Sim" : "Não";
    if (!isNaN(Number(r)))
      return Number(r).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    if (typeof(r) == "object") {
      return <table style={{width: "100%"}}>
        <tbody>
          <tr>{Object.keys(r).map(v=>
            <td style={{width: (100/Object.keys(r).length) + "%", padding: 13, border: "1px solid"}}>
              <b>{v}</b>: {toString(r[v])}
            </td>)}
          </tr>
        </tbody>
      </table>
    }
    if (isValidDataStructure(r))
      return transformToHTMLTable(r);
    if (isProbablyHTML(r)) {
      if (r.includes("<html") || r.includes("<head>") || r.includes("<body>"))
        return <center style={{background: "white"}}><iframe id="main" ref={iframeRef} style={{width: "90%", height: 600, overflow: "hidden"}} srcDoc={r}></iframe></center>
      else
        return <div style={{zoom:0.75, fontWeight: "normal"}} dangerouslySetInnerHTML={{ __html: r }}/>;
    }
    return r;
  }

  const generateHTMLFromJSON = (json) => {
    const hashLI = typeof(json?.parameters) !== "string";
    const question = json && json["Pergunta melhorada"];
    const report = json && json["Relatório"];
    const primaryReason = json && json["Racional de cálculo"];
    const primaryFormula = json && json["Fórmula principal"];
    const primaryResult = json && json["Resultado"];
    const preliminarReason = json && json["Explicação das fórmulas intermediárias"];
    const preliminarFormula = json && json["Fórmulas intermediárias"];
    const preliminarResult = json && json["Preliminares"];
    return (<>
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        <li className="nav-item tab1" role="presentation">
          <button className="nav-link active" id="ans-tab" data-bs-toggle="tab" data-bs-target="#ans" type="button" role="tab" aria-controls="ans" aria-selected="true">Relatório final</button>
        </li>
        <li className="nav-item tab2" role="presentation">
          <button className="nav-link" id="rawans-tab" data-bs-toggle="tab" data-bs-target="#rawans" type="button" role="tab" aria-controls="rawans" aria-selected="false">Resposta bruta</button>
        </li>
        <li className="nav-item tab3" role="presentation">
          <button className="nav-link" id="reason-tab" data-bs-toggle="tab" data-bs-target="#reason" type="button" role="tab" aria-controls="reason" aria-selected="false">Racional de cálculo</button>
        </li>
        <li className="nav-item tab4" role="presentation">
          <button className="nav-link" id="code-tab" data-bs-toggle="tab" data-bs-target="#code" type="button" role="tab" aria-controls="code" aria-selected="false">Código</button>
        </li>
      </ul>
      <div className="tab-content" id="myTabContent">
        <div className="tab-pane fade show active" id="ans" role="tabpanel" aria-labelledby="ans-tab">
          <div className="container">
            <div className="rationale">
              <h5 style={{ marginTop: 10, color: 'rgb(255 226 123)', fontWeight: 'bolder' }}>{question}</h5>
              <br/>
              {report && <div id="report_response">
                {/*<button onClick={reactToPrintFn} 
                  style={{
                    float: "right",
                    backgroundColor: '#007BFF',
                    border: 'none',
                    color: 'white',
                    padding: '15px 32px',
                    textAlign: 'center',
                    textDecoration: 'none',
                    display: 'inline-block',
                    fontSize: '16px',
                    margin: '4px 2px',
                    cursor: 'pointer',
                    borderRadius: '8px',
                    zoom: 0.7
                  }}>
                  Imprimir
                </button>*/}
                <div ref={contentRef}>
                  {toString(report)}
                </div>
              </div>}
            </div>
          </div>
        </div>
        <div className="tab-pane fade" id="rawans" role="tabpanel" aria-labelledby="rawans-tab">
          <div className="container">
            <div className="rationale">
              <h5 style={{ marginTop: 10, color: 'rgb(255 226 123)', fontWeight: 'bolder' }}>{question}</h5>
              <br/>
              <div>
                <p><b>Para chegar lá, executamos os seguintes passos:</b> <br/> 
                {toString(primaryReason)}</p>
                {primaryResult && <span> {toString(primaryResult)}</span>}
              </div>
            </div>
          </div>
        </div>
        <div className="tab-pane fade" id="reason" role="tabpanel" aria-labelledby="reason-tab">
          <div className="container">
            <div className="rationale">
              <h5 style={{ marginTop: 10, color: 'rgb(255 226 123)', fontWeight: 'bolder' }}>{question}</h5>
              <br/>
              {preliminarReason && <div>
                {preliminarReason?.map((o, i)=>{
                  return <div>
                    <div style={{fontWeight: "normal", fontSize: "13pt"}}>{preliminarReason[i]}</div>
                    {preliminarResult && preliminarResult[i]  && (rational == i ? 
                      <button style={{padding: 8}} onClick={()=>setRational(null)}>Ocultar detalhes...</button> :
                      <button style={{padding: 8}} onClick={()=>setRational(i)}>Ver detalhes...</button>)}
                    <br/><br/>
                    {rational == i && <div style={{zoom: 0.8}}>
                      {preliminarResult && preliminarResult[i] && toString(preliminarResult[i])}
                    </div>}
                  </div>
                })}
              </div>}
            </div>
          </div>
        </div>
        <div className="tab-pane fade" id="code" role="tabpanel" aria-labelledby="code-tab">
          <div className="container">
            <div className="rationale">
              <h5 style={{ marginTop: 10, color: 'rgb(255 226 123)', fontWeight: 'bolder' }}>{question}</h5>
              <br/>              
              {primaryFormula && <>
                <b>CÓDIGO DA FÓRMULA PRINCIPAL</b>
                <br/> <br/>
                <code style={{color: "white", fontWeight: "bold", padding: "5px 10px", borderRadius: 10}}>{primaryFormula}</code>
                <br/> <br/> <br/>
                <b>CÓDIGO DAS FÓRMULAS PRELIMINARES</b>
                <br/>
                <ol>
                  {preliminarFormula?.map((o)=>{
                    return <li><code style={{color: "white", padding: "5px 10px", borderRadius: 10}}>{o}</code></li>
                  })}
                </ol>
              </>}
            </div>
          </div>
        </div>
      </div>
    </>);
  };

  return (
    <div style={{paddingBottom: 5}}>
      {generateHTMLFromJSON(message)}
    </div>
  );
};

ResultPanel.propTypes = {
  message: PropTypes.object.isRequired,
};

export default ResultPanel;
